<!--设备详情弹窗-->
<template>
  <page-container title="设备详情" :breadcrumb="breadcrumb">
    <div class="detail-page">
      <div class="left-tabs">
        <a-menu @click="clickLeftMenu" :selected-keys="selectedKeys" :style="{ borderRight: 0 }">
          <a-menu-item key="baseInfo">电梯基本设置</a-menu-item>
<!--          <a-menu-item key="maintenanceRecords">保养记录</a-menu-item>-->
<!--          <a-menu-item key="repairRecords">维修记录</a-menu-item>-->
          <a-menu-item key="checkList">审核记录</a-menu-item>
<!--          <a-menu-item key="examineList">检验记录</a-menu-item>-->
<!--          <a-menu-item key="testList">检测记录</a-menu-item>-->
          <a-menu-item key="malfunction">物联网故障历史</a-menu-item>
          <a-menu-item key="event">AI智能识别事件历史</a-menu-item>
          <a-menu-item key="alarm">报警历史</a-menu-item>
          <a-menu-item key="elevator">电梯事件历史</a-menu-item>
          <a-menu-item key="terminal">物联网终端事件历史</a-menu-item>
        </a-menu>
      </div>
      <div class="page-content">
        <detail-page-base-info v-if="tabKey == 'baseInfo'" :liftid="$route.params.liftid"></detail-page-base-info>
        <detail-page-check-list v-if="tabKey == 'checkList'" :liftid="$route.params.liftid"></detail-page-check-list>
<!--        <detail-page-examine-list v-if="tabKey == 'examineList'" :liftid="$route.params.liftid"></detail-page-examine-list>-->
<!--        <detail-page-test-list v-if="tabKey == 'testList'" :liftid="$route.params.liftid"></detail-page-test-list>-->
        <monitoring-event v-if="tabKey=='malfunction'" key="malfunction" page-type="malfunction" :liftid="$route.params.liftid"></monitoring-event>
        <monitoring-event v-if="tabKey=='event'" key="event" page-type="event" :liftid="$route.params.liftid"></monitoring-event>
        <monitoring-event v-if="tabKey=='alarm'" key="alarm" page-type="alarm" :liftid="$route.params.liftid"></monitoring-event>
        <monitoring-event v-if="tabKey=='elevator'" key="elevator" page-type="elevator" :liftid="$route.params.liftid"></monitoring-event>
        <monitoring-event v-if="tabKey=='terminal'" key="terminal" page-type="terminal" :liftid="$route.params.liftid"></monitoring-event>
      </div>
    </div>
  </page-container>
</template>
<script>
import DetailPageBaseInfo from './DetailPageBaseInfo'
import DetailPageCheckList from './DetailPageCheckList'
import DetailPageExamineList from './DetailPageExamineList'
import DetailPageTestList from './DetailPageTestList'
import MonitoringEvent from './timeMonitoring/MonitoringEvent'
export default {
  name:'elevatorArchivesDetail',
  components: {
    DetailPageBaseInfo,
    // DetailPageMaintenanceList,
    // DetailPageRepairList,
    DetailPageCheckList,
    DetailPageExamineList,
    DetailPageTestList,
    MonitoringEvent,
  },
  data() {
    return {
      breadcrumb: [
        ...this.$route.params.prePages,
        {
          name: '设备详情',
          path: ''
        },
      ],
      tabKey: 'baseInfo'
    }
  },
  computed: {
    selectedKeys() {
      return [this.tabKey]
    }
  },
  methods: {
    clickLeftMenu(event) {
      this.tabKey = event.key
    },
  }
}
</script>

<style lang="scss" scoped>
.detail-page {
  display: flex;
  padding: 24px;
  .left-tabs {
    flex-shrink: 0;
    width: 180px;
    min-height: calc(100vh - 200px);
    padding-top: 20px;
    background-color: white;
    border-radius: 2px;
  }
  .page-content {
    flex-grow: 1;
    margin-left: 24px;
  }
}
</style>