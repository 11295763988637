<template>
  <a-form-model ref="modalForm" layout="inline" class="form-in-twocolumns detail-form-bg" :model="formDatas" :label-col="{span:9}" :wrapper-col="{span:14}">
    <a-tabs type="card" v-model="activeKey" class="empty-tabs">
      <a-tab-pane key="1" tab="基础/设备信息"></a-tab-pane>
      <a-tab-pane key="2" tab="管理信息"></a-tab-pane>
      <a-tab-pane key="3" tab="设备安装信息"></a-tab-pane>
      <a-tab-pane key="4" tab="监测设备信息"></a-tab-pane>
    </a-tabs>
    <div v-show="activeKey == '1'" class="info-card">
      <div class="card-title">基础/设备信息</div>
      <div class="card-content">
        <!-- 基础信息 -->
        <a-form-model-item label="电梯注册码" prop="liftnum">
          <span>{{formDatas.liftnum}}</span>
        </a-form-model-item>
        <a-form-model-item label="救援码" prop="liftrescue">
          <span>{{formDatas.liftrescue}}</span>
        </a-form-model-item>
        <a-form-model-item label="电梯追溯码" prop="traceabilitynum">
          <span>{{formDatas.traceabilitynum}}</span>
        </a-form-model-item>
        <a-form-model-item label="设备代码" prop="devicecode">
          <span>{{formDatas.devicecode}}</span>
        </a-form-model-item>
        <a-form-model-item label="注册登记机构" prop="adminuserdepid">
          <span v-if="formDatas.formalflag==0">{{adminuserdepidDetail}}</span>
          <span v-if="formDatas.formalflag==1">{{formDatas.registerdep}}</span>
        </a-form-model-item>
        <a-form-model-item label="注册登记时间" prop="registertime">
          <span>{{formDatas.registertime ? moment(formDatas.registertime, 'YYYYMMDD').format('YYYY-MM-DD') : ''}}</span>
        </a-form-model-item>
        <a-form-model-item label="注册更新时间" prop="registeruptime">
          <span>{{formDatas.registeruptime ? moment(formDatas.registeruptime, 'YYYYMMDD').format('YYYY-MM-DD') : ''}}</span>
        </a-form-model-item>
        <a-form-model-item label="内部编号" prop="liftcode">
          <span>{{formDatas.liftcode}}</span>
        </a-form-model-item>
        <a-form-model-item label="出厂编号" prop="factorynum">
          <span>{{formDatas.factorynum}}</span>
        </a-form-model-item>
        <a-form-model-item label="厂车牌照编号" prop="factorylicense">
          <span>{{formDatas.factorylicense}}</span>
        </a-form-model-item>
        <a-form-model-item label="操作人员" prop="operator">
          <span>{{formDatas.operator}}</span>
        </a-form-model-item>
        <a-form-model-item label="区域" prop="areacode">
          <span>{{areacodeDetail}}</span>
        </a-form-model-item>
        <a-form-model-item label="安装地址" prop="adress">
          <span>{{formDatas.adress}}</span>
        </a-form-model-item>
        <a-form-model-item label="坐标" prop="coordinatex">
          <span>{{formDatas.coordinatex + ', ' + formDatas.coordinatey}}</span>
        </a-form-model-item>
        <!-- <a-form-model-item label="监管单位" prop="adminuserdepid">
          <span>{{adminuserdepidDetail}}</span>
        </a-form-model-item> -->
        <a-form-model-item label="使用状态" prop="status">
          <span>{{formDatas.status == '1' ? '使用' : '未使用'}}</span>
        </a-form-model-item>
        <a-form-model-item label="使用场所" prop="placeid">
          <span>{{placeidDetail}}</span>
        </a-form-model-item>
        <a-form-model-item label="电梯重要等级" prop="level">
          <span>{{formDatas.level == '0' ? '高' : '一般'}}</span>
        </a-form-model-item>
        <!-- 设备信息 -->
        <a-form-model-item label="用户设备类别" prop="devicetypeid">
          <span>{{devicetypeidDetail}}</span>
        </a-form-model-item>
        <a-form-model-item label="用户设备型号" prop="devicemodel">
          <span>{{formDatas.devicemodel}}</span>
        </a-form-model-item>
        <a-form-model-item label="电梯品牌" prop="brandid">
          <span>{{brandidDetail}}</span>
        </a-form-model-item>
        <a-form-model-item label="设备许可证" prop="devicelicense">
          <span>{{formDatas.devicelicense}}</span>
        </a-form-model-item>
        <a-form-model-item label="许可证有效期" prop="devicelicstarttime">
          <span>{{formDatas.devicelicstarttime ? moment(formDatas.devicelicstarttime, 'YYYYMMDD').format('YYYY-MM-DD') : ''}}</span>
          <span>-</span>
          <span>{{formDatas.devicelicendtime ? moment(formDatas.devicelicendtime, 'YYYYMMDD').format('YYYY-MM-DD') : ''}}</span>
        </a-form-model-item>
        <a-form-model-item label="许可证级别" prop="devicelicenselevel">
          <span>{{formDatas.devicelicenselevel}}</span>
        </a-form-model-item>
        <a-form-model-item label="拖动方式" prop="dragtype">
          <span>{{dragtypeValue[formDatas.dragtype]}}</span>
        </a-form-model-item>
        <a-form-model-item label="最高楼层" prop="floornum">
          <span>{{formDatas.floornum}}</span>
        </a-form-model-item>
        <a-form-model-item label="层数" prop="floorcount">
          <span>{{formDatas.floorcount}}</span>
        </a-form-model-item>
        <a-form-model-item label="站数" prop="stepnum">
          <span>{{formDatas.stepnum}}</span>
        </a-form-model-item>
        <a-form-model-item label="门数" prop="doornum">
          <span>{{formDatas.doornum}}</span>
        </a-form-model-item>
        <a-form-model-item label="额定速度" prop="speed">
          <span>{{formDatas.speed}}</span>
        </a-form-model-item>
        <a-form-model-item label="载重量(kg)" prop="weight">
          <span>{{formDatas.weight}}</span>
        </a-form-model-item>
        <a-form-model-item label="使用登记编号" prop="regesternum">
          <span>{{formDatas.regesternum}}</span>
        </a-form-model-item>
        <a-form-model-item label="使用标志证" prop="userflagurl">
          <a v-if="formDatas.userflagurl" :href="formDatas.userflagurl" target="_blank" rel="noopener noreferrer">{{formDatas.userflagurl.substr(formDatas.userflagurl.lastIndexOf('/')+1)}}</a>
        </a-form-model-item>
        <a-form-model-item label="使用登记证" prop="userloginurl">
          <a v-if="formDatas.userloginurl" :href="formDatas.userloginurl" target="_blank" rel="noopener noreferrer">{{formDatas.userloginurl.substr(formDatas.userloginurl.lastIndexOf('/')+1)}}</a>
        </a-form-model-item>
      </div>
    </div>
    <div  v-show="activeKey == '2'" class="info-card">
      <div class="card-title">管理信息</div>
      <div class="card-content">
        <!-- 使用单位 -->
        <a-form-model-item label="使用单位" prop="userdepid">
          <span v-if="formDatas.formalflag==0">{{userdepidDetail}}</span>
          <span v-if="formDatas.formalflag==1">{{formDatas.userdepname}}</span>
        </a-form-model-item>
        <a-form-model-item label="使用单位代码" prop="userdepid_depnum">
          <span>{{formDatas.userdepid_depnum}}</span>
        </a-form-model-item>
        <a-form-model-item label="营业执照代码" prop="userdepid_businessnum">
          <span>{{formDatas.userdepid_businessnum}}</span>
        </a-form-model-item>
        <a-form-model-item label="使用单位地址" prop="userdepid_address">
          <span>{{formDatas.userdepid_address}}</span>
        </a-form-model-item>
        <a-form-model-item label="物业公司所属品牌" prop="userdepid_liftpropertyname">
          <span>{{formDatas.userdepid_liftpropertyname}}</span>
        </a-form-model-item>
        <a-form-model-item label="邮政编码" prop="userdepid_mailcode">
          <span>{{formDatas.userdepid_mailcode}}</span>
        </a-form-model-item>
        <!-- <a-form-model-item label="物业所属品牌" prop="userdepid_liftpropertyid">
          <span>{{formDatas.userdepid_liftpropertyid}}</span>
        </a-form-model-item> -->
        <a-form-model-item label="安全管理员" prop="userdepid_safeperson">
          <span>{{formDatas.userdepid_safeperson}}</span>
        </a-form-model-item>
        <a-form-model-item label="安全管理员电话" prop="userdepid_safephone">
          <span>{{formDatas.userdepid_safephone}}</span>
        </a-form-model-item>
        <a-form-model-item label="负责人" prop="userdeppreson">
          <span>{{formDatas.userdeppreson}}</span>
        </a-form-model-item>
        <a-form-model-item label="负责人电话" prop="userdeppersonphone">
          <span>{{formDatas.userdeppersonphone}}</span>
        </a-form-model-item>
        <a-form-model-item label="小区所属地产品牌" prop="estatemodelid">
          <span>{{estatemodelidDetail}}</span>
        </a-form-model-item>
        <a-form-model-item label="小区" prop="communityname">
          <span>{{formDatas.communityname}}</span>
        </a-form-model-item>
        <!-- 产权单位 -->
        <a-form-model-item label="产权单位名称" prop="propertydep">
          <span>{{formDatas.propertydep}}</span>
        </a-form-model-item>
        <a-form-model-item label="产权单位代码" prop="propertydepnum">
          <span>{{formDatas.propertydepnum}}</span>
        </a-form-model-item>
        <a-form-model-item label="产权单位地址" prop="propertydepadr">
          <span>{{formDatas.propertydepadr}}</span>
        </a-form-model-item>
        <a-form-model-item label="邮政编码" prop="propertydeppost">
          <span>{{formDatas.propertydeppost}}</span>
        </a-form-model-item>
        <a-form-model-item label="产权单位法人" prop="propertydepperson">
          <span>{{formDatas.propertydepperson}}</span>
        </a-form-model-item>
        <a-form-model-item label="产权单位负责人" prop="propertydepadmin">
          <span>{{formDatas.propertydepadmin}}</span>
        </a-form-model-item>
        <a-form-model-item label="电话" prop="propertydepphone">
          <span>{{formDatas.propertydepphone}}</span>
        </a-form-model-item>
        <a-form-model-item label="安装日期" prop="installtime">
          <span>{{formDatas.installtime ? moment(formDatas.installtime, 'YYYYMMDD').format('YYYY-MM-DD') : ''}}</span>
        </a-form-model-item>
        <a-form-model-item label="使用日期" prop="usetime">
          <span>{{formDatas.usetime ? moment(formDatas.usetime, 'YYYYMMDD').format('YYYY-MM-DD') : ''}}</span>
        </a-form-model-item>

        <a-form-model-item label="维保单位" prop="maintenancedepid">
          <span v-if="formDatas.formalflag==0">{{maintenancedepidDetail}}</span>
          <span v-if="formDatas.formalflag==1">{{formDatas.maintenancedep}}</span>
        </a-form-model-item>
        <a-form-model-item label="维保单位代码" prop="maintenancedepid_depnum">
          <span>{{formDatas.maintenancedepid_depnum}}</span>
        </a-form-model-item>
        <a-form-model-item label="资质证书代码" prop="maintenancedepid_certificatenum">
          <span>{{formDatas.maintenancedepid_certificatenum}}</span>
        </a-form-model-item>
        <a-form-model-item label="营业执照代码" prop="maintenancedepid_businessnum">
          <span>{{formDatas.maintenancedepid_businessnum}}</span>
        </a-form-model-item>
        <a-form-model-item label="电梯安装许可证编码" prop="maintenancedepid_licensenum">
          <span>{{formDatas.maintenancedepid_licensenum}}</span>
        </a-form-model-item>
        <a-form-model-item label="单位地址" prop="maintenancedepid_address">
          <span>{{formDatas.maintenancedepid_address}}</span>
        </a-form-model-item>
        <a-form-model-item label="救援人员" prop="rescueperson">
          <span>{{formDatas.rescueperson}}</span>
        </a-form-model-item>
        <a-form-model-item label="救援人员电话" prop="rescuephone">
          <span>{{formDatas.rescuephone}}</span>
        </a-form-model-item>
        <a-form-model-item label="维保负责人1" prop="maintenancedepid_maintenanceperson1">
          <span>{{formDatas.maintenancedepid_maintenanceperson1}}</span>
        </a-form-model-item>
        <a-form-model-item label="维保负责人1电话" prop="maintenancedepid_maintenancephone1">
          <span>{{formDatas.maintenancedepid_maintenancephone1}}</span>
        </a-form-model-item>
        <a-form-model-item label="维保负责人2" prop="maintenancedepid_maintenanceperson2">
          <span>{{formDatas.maintenancedepid_maintenanceperson2}}</span>
        </a-form-model-item>
        <a-form-model-item label="维保负责人2电话" prop="maintenancedepid_maintenancephone2">
          <span>{{formDatas.maintenancedepid_maintenancephone2}}</span>
        </a-form-model-item>
        <a-form-model-item label="区域经理" prop="maintenancedepid_areamanager">
          <span>{{formDatas.maintenancedepid_areamanager}}</span>
        </a-form-model-item>
        <a-form-model-item label="区域经理电话" prop="maintenancedepid_areamanagerphone">
          <span>{{formDatas.maintenancedepid_areamanagerphone}}</span>
        </a-form-model-item>
        <a-form-model-item label="单位经理" prop="maintenancedepid_depmanager">
          <span>{{formDatas.maintenancedepid_depmanager}}</span>
        </a-form-model-item>
        <a-form-model-item label="单位经理电话" prop="maintenancedepid_depmanagerphone">
          <span>{{formDatas.maintenancedepid_depmanagerphone}}</span>
        </a-form-model-item>
        <a-form-model-item label="邮政编码" prop="maintenancedepid_mailcode">
          <span>{{formDatas.maintenancedepid_mailcode}}</span>
        </a-form-model-item>
        <a-form-model-item label="维保类型" prop="maintenancetype">
          <span>{{maintenancetypeDetail}}</span>
        </a-form-model-item>
        <a-form-model-item label="维保组" prop="maintenancegroupid">
          <span>{{maintenancegroupidDetail}}</span>
        </a-form-model-item>
        <a-form-model-item label="大修/改造日期" prop="bigrepairtime">
          <span>{{formDatas.bigrepairtime ? moment(formDatas.bigrepairtime, 'YYYYMMDD').format('YYYY-MM-DD') : ''}}</span>
        </a-form-model-item>
        <a-form-model-item label="大修周期(周)" prop="bigrepaircycle">
          <span>{{formDatas.bigrepaircycle}}</span>
        </a-form-model-item>
        <!-- 检验信息 -->
        <a-form-model-item label="检验单位" prop="examinedepid">
          <span>{{examinedepidDetail}}</span>
        </a-form-model-item>
        <a-form-model-item label="下次检验时间">
          <span>{{examineLists.nextexaminetime?moment(examineLists.nextexaminetime, 'YYYYMMDD').format('YYYY-MM-DD'):''}}</span>
        </a-form-model-item>
        <a-form-model-item label="检验人员">
          <span>{{examineLists.examineperson||''}}</span>
        </a-form-model-item>
        <a-form-model-item label="检验结论">
          <span>{{examineLists.result=='0'?'合格':examineLists.result=='1'?'不合格':''}}</span>
        </a-form-model-item>
        <!-- 检测信息 -->
        <a-form-model-item label="检测单位" prop="testdepid">
          <span v-if="formDatas.formalflag==0">{{testdepidDetail}}</span>
          <span v-if="formDatas.formalflag==1">{{formDatas.testdep}}</span>
        </a-form-model-item>
        <a-form-model-item label="下次检测时间">
          <span>{{testLists.nextexaminetime?moment(testLists.nextexaminetime, 'YYYYMMDD').format('YYYY-MM-DD'):''}}</span>
        </a-form-model-item>
        <a-form-model-item label="检测人员">
          <span>{{testLists.examineperson||''}}</span>
        </a-form-model-item>
        <a-form-model-item label="检测结论">
          <span>{{testLists.result=='0'?'合格':testLists.result=='1'?'不合格':''}}</span>
        </a-form-model-item>
        <!-- 检测信息 -->
        <a-form-model-item label="应急处置单位" prop="rescuedepid">
          <span v-if="formDatas.formalflag==0">{{rescuedepidDetail}}</span>
          <span v-if="formDatas.formalflag==1">{{formDatas.rescuedep}}</span>
        </a-form-model-item>
        <!-- 维保合同信息 -->
        <a-form-model-item label="合同上传地址" prop="maintenancecontacturl">
          <a v-if="formDatas.maintenancecontacturl" :href="formDatas.maintenancecontacturl" target="_blank" rel="noopener noreferrer">{{formDatas.maintenancecontacturl.substr(formDatas.maintenancecontacturl.lastIndexOf('/')+1)}}</a>
        </a-form-model-item>
        <a-form-model-item label="合同有效期" prop="maintenancecontacttime">
          <span>{{formDatas.maintenancecontacttime ? moment(formDatas.maintenancecontacttime, 'YYYYMMDD').format('YYYY-MM-DD') : ''}}</span>
        </a-form-model-item>
        <!-- IP语音网关和可视通讯 -->
        <a-form-model-item label="IP语音网关分机号" prop="ipnetnum">
          <span>{{formDatas.ipnetnum}}</span>
        </a-form-model-item>
        <a-form-model-item label="视频对讲机号" prop="videonum">
          <span>{{formDatas.videonum}}</span>
        </a-form-model-item>
        <!-- 保险信息 -->
        <a-form-model-item label="保险单位" prop="insurancedep">
          <span>{{formDatas.insurancedep}}</span>
        </a-form-model-item>
        <a-form-model-item label="保险单号" prop="insurancenum">
          <span>{{formDatas.insurancenum}}</span>
        </a-form-model-item>
        <a-form-model-item label="理赔电话" prop="insurancephone">
          <span>{{formDatas.insurancephone}}</span>
        </a-form-model-item>
        <a-form-model-item label="理赔有效期" prop="insuranceexpiretime">
          <span>{{formDatas.insuranceexpiretime ? moment(formDatas.insuranceexpiretime, 'YYYYMMDD').format('YYYY-MM-DD') : ''}}</span>
        </a-form-model-item>
      </div>
    </div>
    <div  v-show="activeKey == '3'" class="info-card">
      <div class="card-title">设备安装信息</div>
      <div class="card-content">
        <!-- 设备安装信息 -->
        <a-form-model-item label="安装单位" prop="installdep">
          <span>{{formDatas.installdep}}</span>
        </a-form-model-item>
        <a-form-model-item label="安装单位编码" prop="installdepnum">
          <span>{{formDatas.installdepnum}}</span>
        </a-form-model-item>
        <a-form-model-item label="安装证书编码" prop="installlicnum">
          <span>{{formDatas.installlicnum}}</span>
        </a-form-model-item>
        <a-form-model-item label="安装负责人" prop="installadmin">
          <span>{{formDatas.installadmin}}</span>
        </a-form-model-item>
        <a-form-model-item label="安装负责人电话" prop="installadminphone">
          <span>{{formDatas.installadminphone}}</span>
        </a-form-model-item>
        <a-form-model-item label="安装施工单位" prop="constructiondep">
          <span>{{formDatas.constructiondep}}</span>
        </a-form-model-item>
        <a-form-model-item label="施工日期" prop="constructiontime">
          <span>{{formDatas.constructiontime ? moment(formDatas.constructiontime, 'YYYYMMDD').format('YYYY-MM-DD') : ''}}</span>
        </a-form-model-item>
        <a-form-model-item label="土建验收单位" prop="acceptdep">
          <span>{{formDatas.acceptdep}}</span>
        </a-form-model-item>
        <a-form-model-item label="审核单位" prop="checkunit">
          <span>{{formDatas.checkunit}}</span>
        </a-form-model-item>
        <a-form-model-item label="审核日期" prop="checktime">
          <span>{{formDatas.checktime ? moment(formDatas.checktime, 'YYYYMMDD').format('YYYY-MM-DD') : ''}}</span>
        </a-form-model-item>
        <a-form-model-item label="验收报告编号" prop="checkreportnum">
          <span>{{formDatas.checkreportnum}}</span>
        </a-form-model-item>
        <!-- 设计制造信息 -->
        <a-form-model-item label="设计单位" prop="designdep">
          <span>{{formDatas.designdep}}</span>
        </a-form-model-item>
        <a-form-model-item label="设计单位编码" prop="designdepnum">
          <span>{{formDatas.designdepnum}}</span>
        </a-form-model-item>
        <a-form-model-item label="设备制造商" prop="manufacturerid">
          <span v-if="formDatas.formalflag==0">{{manufactureridDetail}}</span>
          <span v-if="formDatas.formalflag==1">{{formDatas.manufacturerdep}}</span>
        </a-form-model-item>
        <a-form-model-item label="资格证" prop="manufacturerlic">
          <span>{{formDatas.manufacturerlic}}</span>
        </a-form-model-item>
        <a-form-model-item label="生产许可证" prop="productlic">
          <span>{{formDatas.productlic}}</span>
        </a-form-model-item>
        <a-form-model-item label="生产编号" prop="productnum">
          <span>{{formDatas.productnum}}</span>
        </a-form-model-item>
        <a-form-model-item label="生产日期" prop="producttime">
          <span>{{formDatas.producttime ? moment(formDatas.producttime, 'YYYYMMDD').format('YYYY-MM-DD') : ''}}</span>
        </a-form-model-item>
      </div>
    </div>
    <div  v-show="activeKey == '4'" class="info-card">
      <div class="card-title">监测设备信息</div>
      <div class="card-content">
        <!-- 监测设备信息 -->
        <!-- <a-form-model-item label="关联设备" prop="deviceid">
          <span>{{formDatas.deviceid}}</span>
        </a-form-model-item> -->
        <div style="padding: 5px 15px;">
          <a-table v-if="showDeviceTable" :columns="tableColumns" :data-source="deviceList" row-key="deviceinfoid" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 16px;" :pagination="false">
            <span slot="terminaltype" slot-scope="text">
              <span class="text">{{terminaltypesMap[text]||''}}</span>
            </span>
            <span slot="isonline" slot-scope="value">
              <a-tag :color="value=='1'?'green':'red'">{{value=='1'?'在线':'离线'}}</a-tag>
            </span>
          </a-table>
          <a-table v-if="showDeviceTable" :columns="tableColumns1" :data-source="monitorList" row-key="monitorid" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
            <span slot="monitortype" slot-scope="text">
              <span class="text">{{monitortypesMap[text]||''}}</span>
            </span>
            <span slot="isonline" slot-scope="value">
              <a-tag :color="value=='1'?'green':'red'">{{value=='1'?'在线':'离线'}}</a-tag>
            </span>
          </a-table>
        </div>
      </div>
    </div>
  </a-form-model>
</template>
<script>
import moment from 'moment'
import { getParentTreeBycode, getDictByDicType } from 'A/xtpz'
import { getTreeDeepString } from 'U'
import { getLiftListByCondition, getLiftinfoById, getParentTreeByid } from 'A/jcgn'
import { getTerminaltypeListByCondition} from 'A/ai.js'
export default {
  props: ['liftid'],
  data() {
    return {
      moment,
      activeKey: '1',
      formDatas: {
        // 基础信息
        liftid: '',
        liftnum: '',
        liftrescue: '',
        traceabilitynum: '',
        devicecode: '',
        registerdep: '',
        registertime: '',
        registeruptime: '',
        liftcode: '',
        factorynum: '',
        factorylicense: '',
        operator: '',
        areacode: '',
        adress: '',
        adminuserdepid: '',
        coordinatex: '',
        coordinatey: '',
        status: 1,//0-未使用，1-使用
        placeid: '',
        level: '',
        // 设备信息
        devicetypeid: '',
        devicemodelid: '',
        devicemodelname: '',
        devicemodel:'',
        brandid: '',
        devicelicense: '',
        devicelicstarttime: '',
        devicelicendtime: '',
        devicelicenselevel: '',
        dragtype: '',
        floornum: '',
        floorcount: '',
        stepnum: '',
        doornum: '',
        speed: '',
        weight: '',
        // 设备使用标志和登记信息证件查看
        regesternum: '',
        userflagurl: '',
        userloginurl: '',
        // 使用单位信息
        userdepid: '',
        userdepid_depnum: '',
        userdepid_businessnum: '',
        userdepid_address: '',
        userdepid_liftpropertyname: '',
        userdepid_mailcode: '',
        userdepid_safeperson: '',
        userdepid_safephone: '',
        userdepid_liftpropertyid: '',
        userdeppreson: '',
        userdeppersonphone: '',
        estatemodelid: '',
        communityid: '',
        communityname: '',
        // 产权单位信息
        propertydep: '',
        propertydepnum: '',
        propertydepadr: '',
        propertydeppost: '',
        propertydepperson: '',
        propertydepadmin: '',
        propertydepphone: '',
        installtime: '',
        usetime: '',
        // 设备维保信息
        maintenancedepid: '',
        maintenancedepid_depnum: '',//维保单位代码
        maintenancedepid_certificatenum: '',//资质证书代码
        maintenancedepid_businessnum: '',//营业执照代码
        maintenancedepid_licensenum: '',//电梯安装许可证编码
        maintenancedepid_address: '',//单位地址
        rescueperson:'',
        rescuephone:'',
        maintenancedepid_rescuepreson: '',//救援人员
        maintenancedepid_rescuephone: '',//救援人员电话
        maintenancedepid_maintenanceperson1: '',//维保负责人1
        maintenancedepid_maintenancephone1: '',//维保负责人1电话
        maintenancedepid_maintenanceperson2: '',//维保负责人2
        maintenancedepid_maintenancephone2: '',//维保负责人2电话
        maintenancedepid_areamanager: '',//区域经理
        maintenancedepid_areamanagerphone: '',//区域经理电话
        maintenancedepid_depmanager: '',//单位经理
        maintenancedepid_depmanagerphone: '',//单位经理电话
        maintenancedepid_mailcode: '',//单位经理电话
        maintenancetype: '',
        maintenancegroupid: '',
        // 检验信息
        examinedepid: '',
        testdepid: '',
        rescuedepid: '',
        bigrepairtime: '',
        bigrepaircycle: '',
        // 维保合同信息
        maintenancecontacturl: '',
        maintenancecontacttime: '',
        // IP语音网关和可视通讯
        ipnetnum: '',
        videonum: '',
        // 保险信息
        insurancedep: '',
        insurancenum: '',
        insurancephone: '',
        insuranceexpiretime: '',
        // 设备安装信息
        installdep: '',
        installdepnum: '',
        installlicnum: '',
        installadminphone: '',
        installadmin: '',
        constructiondep: '',
        constructiontime: '',
        acceptdep: '',
        checkunit: '',
        checktime: '',
        checkreportnum: '',
        // 设计制造信息
        designdep: '',
        designdepnum: '',
        manufacturerid: '',
        manufacturerlic: '',
        productlic: '',
        productnum: '',
        producttime: '',
        // 审核状态
        checkstatus: '',
        // 关联设备
        deviceid: '',

        formalflag:0,
      },
      areacodeDetail: '',
      adminuserdepidDetail: '',
      userdepidDetail: '',
      maintenancedepidDetail: '',
      examinedepidDetail: '',
      testdepidDetail: '',
      rescuedepidDetail: '',
      placeidDetail: '',
      devicetypeidDetail: '',
      brandidDetail: '',
      dragtypeDetail: '',
      estatemodelidDetail: '',
      maintenancetypeDetail: '',
      manufactureridDetail: '',
      maintenancegroupidDetail: '',
      examineLists: {},
      testLists: {},
      tableColumns: [
        {
          title: '设备名称',
          dataIndex: 'devicename',
          key: 'devicename',
          ellipsis: true,
        },
        {
          title: '注册代码',
          dataIndex: 'devicenum',
          key: 'devicenum',
          ellipsis: true,
        },
        {
          title: '设备型号',
          dataIndex: 'terminaltype',
          key: 'terminaltype',
          ellipsis: true,
          scopedSlots: { customRender: 'terminaltype' }
        },
        {
          title: '在线状态',
          dataIndex: 'isonline',
          key: 'isonline',
          ellipsis: true,
          scopedSlots: { customRender: 'isonline' }
        },
      ],
      tableColumns1: [
        {
          title: '显示屏名称',
          dataIndex: 'monitorname',
          key: 'monitorname',
          ellipsis: true,
        },
        {
          title: '显示屏编号',
          dataIndex: 'monitornum',
          key: 'monitornum',
          ellipsis: true,
        },
        {
          title: '显示屏型号',
          dataIndex: 'monitortype',
          key: 'monitortype',
          ellipsis: true,
          scopedSlots: { customRender: 'monitortype' }
        },
        {
          title: '在线状态',
          dataIndex: 'isonline',
          key: 'isonline',
          ellipsis: true,
          scopedSlots: { customRender: 'isonline' }
        },
      ],
      deviceList: null,
      monitorList: null,
      showDeviceTable: false,
      terminaltypes: [],
      monitortypes:[],
      dragtypeValue:['交流双速', '调压调速', '变频', '其他'],
    }
  },
  computed: {
    terminaltypesMap() {
      let result = {};
      this.terminaltypes.forEach(item => {
        result[item.value] = item.text;
      })
      return result;
    },
    monitortypesMap() {
      let result = {};
      this.monitortypes.forEach(item => {
        result[item.value] = item.text;
      })
      return result;
    },
  },
  watch: {
    'formDatas.areacode'(val) {
      if(val) {
        this.getareacodeDetail(val);
      }else {
        this.areacodeDetail = '';
      }
    },
    'formDatas.adminuserdepid'(val) {
      if(val) {
        this.getDeptDetail(val, 'adminuserdepidDetail');
      }else {
        this.adminuserdepidDetail = '';
      }
    },
    'formDatas.userdepid'(val) {
      if(val) {
        this.getDeptDetail(val, 'userdepidDetail');
      }else {
        this.userdepidDetail = '';
      }
    },
    'formDatas.maintenancedepid'(val) {
      if(val) {
        this.getDeptDetail(val, 'maintenancedepidDetail');
      }else {
        this.maintenancedepidDetail = '';
      }
    },
    'formDatas.examinedepid'(val) {
      if(val) {
        this.getDeptDetail(val, 'examinedepidDetail');
      }else {
        this.examinedepidDetail = '';
      }
    },
    'formDatas.testdepid'(val) {
      if(val) {
        this.getDeptDetail(val, 'testdepidDetail');
      }else {
        this.testdepidDetail = '';
      }
    },
    'formDatas.rescuedepid'(val) {
      if(val) {
        this.getDeptDetail(val, 'rescuedepidDetail');
      }else {
        this.rescuedepidDetail = '';
      }
    },
    'formDatas.placeid'(val) {
      if(val) {
        this.getLiftPropertyDetail(val, '4', 'placeidDetail');
      }else {
        this.placeidDetail = '';
      }
    },
    'formDatas.devicetypeid'(val) {
      if(val) {
        this.getLiftPropertyDetail(val, '1', 'devicetypeidDetail');
      }else {
        this.devicetypeidDetail = '';
      }
    },
    'formDatas.brandid'(val) {
      if(val) {
        this.getLiftPropertyDetail(val, '3', 'brandidDetail');
      }else {
        this.brandidDetail = '';
      }
    },
    'formDatas.dragtype'(val) {
      if(val) {
        this.getLiftPropertyDetail(val, '8', 'dragtypeDetail');
      }else {
        this.dragtypeDetail = '';
      }
    },
    'formDatas.estatemodelid'(val) {
      if(val) {
        this.getLiftPropertyDetail(val, '5', 'estatemodelidDetail');
      }else {
        this.estatemodelidDetail = '';
      }
    },
    'formDatas.manufacturerid'(val) {
      if(val) {
        this.getLiftPropertyDetail(val, '6', 'manufactureridDetail');
      }else {
        this.manufactureridDetail = '';
      }
    },
    'formDatas.maintenancetype'(val) {
      if(val) {
        this.getDictDetail(val, '14', 'maintenancetypeDetail');
      }else {
        this.maintenancetypeDetail = '';
      }
    },
    'formDatas.maintenancegroupid'(val) {
      if(val) {
        this.getMaintenancegroupDetail(val, 'maintenancegroupidDetail');
      }else {
        this.maintenancegroupidDetail = '';
      }
    },
  },
  created() {
    this.initDetail();
    this.getTerminaltypes();
  },
  methods: {
    initDetail() {
      if(this.liftid) {
        let params = {
          liftid: this.liftid
        }
        getLiftinfoById(params).then(res => {
          if(res && res.returncode == '0') {
            for(let key in this.formDatas) {
              if(res.item[key] || res.item[key] === 0) {
                this.formDatas[key] = res.item[key];
              }
            }
            this.formDatas.formalflag=res.item.formalflag
            this.formDatas.userdepname=res.item.userdep
            this.formDatas.maintenancedep=res.item.maintenancedep
            this.formDatas.manufacturerdep=res.item.manufacturerdep
            this.formDatas.rescuedep=res.item.rescuedep
            this.formDatas.testdep=res.item.testdep
            // 使用单位信息
            if(res.item.userdepLists) {
              this.formDatas.userdepid_depnum = res.item.userdepLists.depnum || '';
              this.formDatas.userdepid_businessnum = res.item.userdepLists.businessnum || '';
              this.formDatas.userdepid_address = res.item.userdepLists.address || '';
              this.formDatas.userdepid_mailcode = res.item.userdepLists.mailcode || '';
              this.formDatas.userdepid_safeperson = res.item.userdepLists.safeperson || '';
              this.formDatas.userdepid_safephone = res.item.userdepLists.safephone || '';
              this.formDatas.userdepid_liftpropertyid = res.item.userdepLists.liftpropertyid || '';
            }
            // 维保单位信息
            if(res.item.maintenancedepLists) {
              this.formDatas.maintenancedepid_depnum = res.item.maintenancedepLists.depnum || '';
              this.formDatas.maintenancedepid_certificatenum = res.item.maintenancedepLists.certificatenum || '';
              this.formDatas.maintenancedepid_businessnum = res.item.maintenancedepLists.businessnum || '';
              this.formDatas.maintenancedepid_licensenum = res.item.maintenancedepLists.licensenum || '';
              this.formDatas.maintenancedepid_address = res.item.maintenancedepLists.address || '';
              this.formDatas.maintenancedepid_rescuepreson = res.item.maintenancedepLists.rescuepreson || '';
              this.formDatas.maintenancedepid_rescuephone = res.item.maintenancedepLists.rescuephone || '';
              this.formDatas.maintenancedepid_maintenanceperson1 = res.item.maintenancedepLists.maintenanceperson1 || '';
              this.formDatas.maintenancedepid_maintenancephone1 = res.item.maintenancedepLists.maintenancephone1 || '';
              this.formDatas.maintenancedepid_maintenanceperson2 = res.item.maintenancedepLists.maintenanceperson2 || '';
              this.formDatas.maintenancedepid_maintenancephone2 = res.item.maintenancedepLists.maintenancephone2 || '';
              this.formDatas.maintenancedepid_areamanager = res.item.maintenancedepLists.areamanager || '';
              this.formDatas.maintenancedepid_areamanagerphone = res.item.maintenancedepLists.areamanagerphone || '';
              this.formDatas.maintenancedepid_depmanager = res.item.maintenancedepLists.depmanager || '';
              this.formDatas.maintenancedepid_depmanagerphone = res.item.maintenancedepLists.depmanagerphone || '';
              this.formDatas.maintenancedepid_mailcode = res.item.maintenancedepLists.mailcode || '';
            }
            // 检验信息
            if(res.item.examineLists) {
              this.examineLists = res.item.examineLists;
            }else {
              this.examineLists = {};
            }
            if(res.item.testLists) {
              this.testLists = res.item.testLists;
            }else {
              this.testLists = {};
            }
            if(res.item.monitorinfos && res.item.monitorinfos.length) {
              this.monitorList = res.item.monitorinfos.map(item => {
                item.monitorid = ''+item.monitorid;
                return item;
              });
            }
            if(res.item.deviceinfos && res.item.deviceinfos.length) {
              this.deviceList = res.item.deviceinfos.map(item => {
                item.deviceinfoid = ''+item.deviceinfoid;
                return item;
              });
            }
          }
        })
      }
    },
    getTerminaltypes() {
      getTerminaltypeListByCondition({}).then(res => {
        if(res && res.returncode == '0') {
          this.terminaltypes = res.item.map(item => {
            return {
              value: item.terminaltypeid,
              text: item.terminaltypename,
            }
          });
          this.showDeviceTable = true;
        }
      })
    },
    getareacodeDetail(areacode) {
      let params = {
        areacode
      };
      getParentTreeBycode(params).then(res => {
        if(res && res.returncode == '0') {
          let treeStr = getTreeDeepString(res.item, '', 'areaname', 'childArea');
          this.areacodeDetail = treeStr.replace(/\|/g, '-');
        }
      })
    },
    getDeptDetail(userdepid, detailKey) {
      let params = {
        userdepid
      };
      getParentTreeByid(params).then(res => {
        if(res && res.returncode == '0') {
          let treeStr = getTreeDeepString(res.item, '', 'userdepname', 'childUserdep');
          this[detailKey] = treeStr.replace(/\|/g, '-');
        }
      })
    },
    getLiftPropertyDetail(val, liftpropertytype, detailKey) {
      let params = {
        liftpropertytype
      }
      getLiftListByCondition(params).then(res => {
        if(res && res.returncode == '0') {
          for(let i=0; i<res.item.length; i++) {
            if(res.item[i].liftpropertyid == val) {
              this[detailKey] = res.item[i].value;
              break;
            }
          }
        }
      })
    },
    getDictDetail(val, dictype, detailKey) {
      let params = {
        dictype
      }
      getDictByDicType(params).then(res => {
        if(res && res.returncode == '0') {
          for(let i=0; i<res.item.length; i++) {
            if(res.item[i].dickey == val) {
              this[detailKey] = res.item[i].dicvalue;
              break;
            }
          }
        }
      })
    },
    getMaintenancegroupDetail(val, detailKey) {
      let params = {
        maintenancedepid: this.formDatas.maintenancedepid
      }
      // getMentenacegroupListByCondition(params).then(res => {
      //   if(res && res.returncode == '0') {
      //     for(let i=0; i<res.item.length; i++) {
      //       if(res.item[i].maintenancegroupid == val) {
      //         this[detailKey] = res.item[i].maintenancegroupname
      //         break;
      //       }
      //     }
      //   }
      // })
    },
    initPackageDatas() {
      this.initPlaceidOptions();
      this.initDevicetypeidOptions();
      this.initBrandidOptions();
      this.initDragtypeOptions();
      this.initEstatemodelidOptions();
      this.initMaintenancetypeOptions();
      this.initManufactureridOptions();
    },
    initPlaceidOptions() {
      let params = {
        liftpropertytype: '4',
      }
      getLiftListByCondition(params).then(res => {
        if(res && res.returncode == '0') {
          this.placeidOptions = res.item;
        }
      })
    },
    initDevicetypeidOptions() {
      let params = {
        liftpropertytype: '1',
      }
      getLiftListByCondition(params).then(res => {
        if(res && res.returncode == '0') {
          this.devicetypeidOptions = res.item;
        }
      })
    },
    initBrandidOptions() {
      let params = {
        liftpropertytype: '3',
      }
      getLiftListByCondition(params).then(res => {
        if(res && res.returncode == '0') {
          this.brandidOptions = res.item;
        }
      })
    },
    initDragtypeOptions() {
      let params = {
        dictype: '13',
      }
      getDictByDicType(params).then(res => {
        if(res && res.returncode == '0') {
          this.dragtypeOptions = res.item;
        }
      })
    },
    initEstatemodelidOptions() {
      let params = {
        liftpropertytype: '5',
      }
      getLiftListByCondition(params).then(res => {
        if(res && res.returncode == '0') {
          this.estatemodelidOptions = res.item;
        }
      })
    },
    initMaintenancetypeOptions() {
      let params = {
        dictype: '14',
      }
      getDictByDicType(params).then(res => {
        if(res && res.returncode == '0') {
          this.maintenancetypeOptions = res.item;
        }
      })
    },
    initManufactureridOptions() {
      let params = {
        liftpropertytype: '6',
      }
      getLiftListByCondition(params).then(res => {
        if(res && res.returncode == '0') {
          this.manufactureridOptions = res.item;
        }
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.info-card {
  background-color: white;
  border-radius: 2px;
  // height: calc(100vh - 240px);
  // overflow: auto;
  // margin-top: 17px;
  // &:first-child {
  //   margin-top: 0;
  // }
  .card-title {
    display: none;
    height: 55px;
    line-height: 55px;
    border-bottom: solid 1px #e9e9e9;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
    padding-left: 32px;
  }
  .card-content {
    padding-bottom: 15px;
  }
}
</style>